<template>
   <div class="container">
      <div class="title mb-7">{{ $t('headerUserCenterNavMyFavorites') }}</div>
         <CardList :list="list"  v-scroll="onScroll"  type='collect' :isShowLoading="isShowLoading" />
          <div v-if="list.length == 0" class="empty">{{ $t("EmptyHere") }}</div>
   </div>
</template>

<script>
   import CardList from "@/components/workCard/CardList.vue";
   import api from  "@/api"

   export default {
      components: {CardList},
      data: () => ({
         list: [],
         pageNumber: 0,
         pageSize: 20,
         totalPage: false,
         isShowLoading: false,
      }),
      mounted() {
         this.getColleectList();
      },
      methods:{
         async getColleectList(){
            this.pageNumber++;
            let params={
               followType:'1',
               pageSize:this.pageSize,
               pageNumber:this.pageNumber,
               did:this.$store.state.did
            };
            this.isShowLoading = true;
            let res= await api.home.followList(params);
            let {list,totalPage} = res.data;
            this.totalPage = totalPage;
            this.list = this.list.concat(list);
           
            this.isShowLoading = false;
         },

         onScroll(e) {
            const { scrollTop, clientHeight, scrollHeight } =
              e.target.scrollingElement;
            if (scrollTop + clientHeight === scrollHeight && (this.totalPage > this.pageNumber)) {
               this.getColleectList();
            }
         },
      },
   };
</script>

<style lang="scss" scoped>
.empty {
					width: 100%;
					height: 500px;
					display: flex;
					justify-content: center;
					align-items: center;
				}
   .container {
      padding: 0;
      margin: 0;

      .title {
        font-family:Helvetica;
         font-size: 40px;
         font-weight: bold;
         font-stretch: normal;
         letter-spacing: 0px;
         color: #270645;
      }
   }
</style>
